import {Auth, Storage} from 'aws-amplify';
import {createHttpLink} from 'apollo-link-http';
import {ApolloLink} from 'apollo-link';
import ApolloClientIDB from '@wora/apollo-offline/lib/ApolloClientIDB';
import get from 'lodash/get';
import React, {useEffect, useContext, useState, useCallback, useRef} from 'react';
import ReactDOM from 'react-dom';
import {IntlProvider} from 'react-intl';
import {RecoilRoot, atom, useRecoilState} from 'recoil';
import {ENDPOINT, BUCKET_NAME} from './Constants';
import AppearanceSettingProvider from './fhg/components/AppearanceSettingProvider';
import App from './pages/App';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import {BrowserRouter} from 'react-router-dom';
import './index.css';
import {ApolloProvider} from '@apollo/react-hooks';
import {RetryLink} from 'apollo-link-retry';
import {setContext} from 'apollo-link-context';
import StatusContext from './fhg/components/StatusContext';
import localForage from 'localforage';

// Tracks the status for downloding all the offline records and photos.
export const downLoadForOfflineStatus = atom({
   key: 'isDownloadForOffline',
   default: {shouldDownload: false, isDownloading: false, downloadComplete: false, userNotified: false},
});

//Set the Index DB name to Photos.
localForage.config({
   name: 'Photos',
});

//Tracks the message status to show messages to the user, with optional progress.
export const messageStatus = atom({
   key: 'messageStatus',
   default: {
      open: false,
      autoHideDuration: null,
      index: 0,
      total: 0,
      error: undefined,
      messageKey: undefined,
      messageValues: undefined
   },
});

const uri = ENDPOINT;
/*
Auth: {
         // REQUIRED - Amazon Cognito Identity Pool ID
         identityPoolId: 'us-east-2:fc2ef06e-cf67-4b73-9da8-090e59828afe',
         // REQUIRED - Amazon Cognito Region
         region: 'us-east-2',
         // OPTIONAL - Amazon Cognito User Pool ID
         userPoolId: 'us-east-2_juU9d1lC5',
         // OPTIONAL - Amazon Cognito Web Client ID
         userPoolWebClientId: '730rts1ddvhja0k0nv09hvthm2',
},
 */

const httpLink = createHttpLink({uri});
const retryLink = new RetryLink();

const getAccessToken = () => {
   return Auth.currentSession()
      .then(session => {
         return session.accessToken.jwtToken;
      })
      .catch(error => {
         console.log(error);
         throw error;
      });
};

const authLink = setContext(async (_, {headers}) => {
   let token = await getAccessToken();

   return {
      headers: {
         ...headers,
         accesstoken: token || '',
      }
   }
});

/**
 * Maps the objects in the offline cache to a unique ID. Use the UUID unless it doesn't exist and then use typename and
 * id.
 *
 * @type {{dataIdFromObject: (function(*): *|string)}}
 */
const cacheOptions = {
   dataIdFromObject: o => o.uuid !== undefined ? o.uuid : o.__typename + ' ' + o.id,
}

/**
 * Create the Apollo client for offline use. The Index DB is used to cache offline records.
 * @type {ApolloClientIDB}
 */
const client = ApolloClientIDB.create({
   link: ApolloLink.from([
      retryLink,
      authLink,
      httpLink,
   ])
}, {
   cacheOptions
});

// Add the format command for adding parameters to strings. For Example:
//    'This is a test: {testName}'.format({testName: 'Test Hello World'})
if (!String.prototype.format) {
// eslint-disable-next-line
   String.prototype.format = function (values) {
      return this.replace(/{(\w+)}/g, function (match, key) {
         return typeof values[key] !== 'undefined' ? values[key] : match;
      });
   };
}

// Used to create a separate chunks for all the code. If we don't do this, all the code is in a single chunk.
const messageLoader = {
   en: () => import('./messages/en-US')
}

/**
 * Loader is a component used to setup the Apollo client for offline use.
 *
 * @return {JSX.Element|null}
 * @constructor
 */
function Loader() {
   const {setErrorGeneral} = useContext(StatusContext);
   const [isHydrated, setIsHydrated] = useState(false);
   const [messageState, setMessageState] = useRecoilState(messageStatus);
   const status = useRef({index: 0, total: 0}).current;

   /**
    * Callback to start the upload of offline records.
    *
    * @type {function(*=): any}
    */
   const start = useCallback(async (mutations) => {
      console.log('start offline', mutations);

      if (mutations?.length > 0) {
         status.total = mutations.length;
         status.index = 0;
         setMessageState({...status, open: true, allowClose: false, index: 0, total: mutations.length, messageKey: 'offline.start.message'});
      }
      return mutations;
   }, [messageState]);

   /**
    * Callback when the data has finished hydrating.
    *
    * @type {function(*=, *=): Promise<void>}
    */
   const finish = useCallback(async (mutations, error) => {
      console.log('finish offline', error, mutations);

      if (error) {
         setErrorGeneral('offline.error.message', undefined, error);
      } else if (status.total > 0) {
         //Remove all the photos remaining. If everything was successful, we clear any remaining photos.
         await localForage.clear();
         setMessageState(
            {...status, open: false, autoHideDuration: 3000, allowClose: true, messageKey: 'offline.finish.message'});
         status.total = 0;
      }
   }, [messageState]);

   useEffect(() => {
      client.setOfflineOptions({
         manualExecution: false, //optional
         link: httpLink, //optional
         start,
         finish,
         onExecute: async mutation => {
            const uploadImage = async () => {
               try {
                  console.log('Uploading photo', imageLocation);

                  const imageKey = imageLocation.substring(BUCKET_NAME.length + 1);
                  const photoUri = await localForage.getItem(imageKey);

                  await Storage.put(imageKey, photoUri.blob,
                     {level: 'public', contentType: get(photoUri.blob, 'blob.type', 'image/jpeg')});
                  console.log('Uploading photo successful', imageLocation);
                  await localForage.removeItem(imageKey);
               } catch (e) {
                  console.log('Error uploading photo', imageLocation, e);
                  setErrorGeneral('offline.photoError.message', undefined, e);
               }
            }

            const imageLocation = get(mutation, 'request.payload.variables.imageData.imageLocation')
            if (imageLocation) {
               await uploadImage();
            }

            console.log('onExecute offline', mutation);
            return mutation;
         },
         onComplete: async options => {
            console.log('onComplete offline', options);

            const refetchQueries = get(options, 'offlinePayload.request.payload.optimisticResponse.refetchQueries');
            if (refetchQueries) {
               const queries = refetchQueries();
               for (const query of queries) {
                  const result = await client.query({...query, fetchPolicy: 'network-only'});
                  console.log('Result = ', result);
               }
            }
            status.index += 1;
            setMessageState({...status, open: true, messageKey: 'offline.progress.message'});
            return true;
         },
         onDiscard: async options => {
            console.log('onDiscard offline', options);
            setErrorGeneral('offline.requestError.message', options.error);
            return true;
         },
      });
   }, [messageState]);

   useEffect(() => {
      const hydrate = async () => {
         await client.hydrate();
         setIsHydrated(true);
      };
      hydrate();
   }, []);

   if (isHydrated) {
      return (
         <ApolloProvider client={client}>
            <BrowserRouter>
               <AppearanceSettingProvider>
                  <App/>
               </AppearanceSettingProvider>
            </BrowserRouter>
         </ApolloProvider>
      );
   } else {
      return null;
   }
}

function Loader2() {

   const [messages, setMessages] = React.useState({});

   useEffect(() => {
      messageLoader.en().then((messages) => {
         setMessages(messages);
      });
   }, []);

   return (
      <IntlProvider messages={messages} locale={'en'}>
         <Loader/>
      </IntlProvider>

   );
}

function registerServiceWorker() {
   return new Promise((resolve) => {
      const onUpdate = (registration) => {
         resolve({update: true, registration});
      };
      const onSuccess = (registration) => {
         resolve({update: false, registration});
      };
      // If you want your app to work offline and load faster, you can change
      // unregister() to register() below. Note this comes with some pitfalls.
      // Learn more about service workers: http://bit.ly/CRA-PWA
      serviceWorkerRegistration.register({onUpdate, onSuccess});
   });
}

export const registerServiceWorkerPromise = registerServiceWorker();

ReactDOM.render(<RecoilRoot><Loader2/></RecoilRoot>, document.getElementById('root'));
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
// serviceWorker.register({onUpdate: handleUpdate, onSuccess: handleSuccess});

import gql from 'graphql-tag';

//Maintain all the Fragments here so they can be loaded first before queries and be reused everywhere to make the
// caching work.
export const FIELD_FRAGMENT = gql`
   fragment fieldInfo on Field {
      id
      uuid
      acreage
      latitude
      longitude
      nmpMaxRate
      name
      fieldCoverId
      facilityId
      boundaryData
      isDeleted
      isNmpMaxRateLiquid
   }
`;

export const FIELD_FULL_FRAGMENT = gql`
   fragment fieldFullInfo on Field {
      id
      uuid
      acreage
      annualSum
      fieldCoverId
      facilityId
      nmpMaxRate
      nmpMaxRateHistory
      latitude
      longitude
      name
      boundaryData
      isDeleted
      isNmpMaxRateLiquid
   }
`;

export const WELL_FRAGMENT = gql`
   fragment wellInfo on Well {
      id
      uuid
      commonName
      facilityId
      name: commonName
      latitude
      longitude
      isDeleted
      allotments {
         id
         uuid
         years
         startYear
         allotment
         fileNumbers
         facilityId
         isDeleted
      }
   }
`;
export const ALLOTMENT_FRAGMENT = gql`
   fragment allotmentInfo on Allotment {
      id
      uuid
      fileNumbers
      facilityId
      allotment
      startYear
      years
      isDeleted
      wellIdList
   }
`;

export const METER_FRAGMENT = gql`
   fragment meterInfo on Meter {
      id
      uuid
      wellId
      name
      digits
      allotment {
         allotment
         fileNumbers
      }
      unitTypeId
      acreFeetMultiplier
      acreInchesMultiplier
      beginningMeter
      date
      replacedDate
      gallonsUnitMultiplier
      useTypeId
      useType {
         id
         name
      }
      isDeleted
   }
`;

// Default properties needed for the Waste Export.
export const WASTE_EXPORT_FRAGMENT = gql`
   fragment wasteExportOnWasteExport on WasteExport {
      id
      addressLineOne
      addressLineTwo
      amount
      analysisProvided
      cityId
      city {
         id
         name
         uuid
      }
      startDate
      stopDate
      facilityId
      recipientName
      stateId
      state {
         id
         name
         uuid
      }
      uuid
      wasteTypeId
      zipCode
      isDeleted
   }
`;

export const WATER_REPORT_FRAGMENT = gql`
   fragment waterReportInfo on WaterReportData {
      id
      averageHeadCount
      gallonsPerHeadPerDay
      endMeterReadings {
         id
         endMeterReading
      }
      totalUsed
   }
`;

export const LIVESTOCK_FRAGMENT = gql`
   fragment livestockInfoForEdit on Livestock {
      id
      uuid
      facilityId
      max
      livestockTypeId
      isDeleted
   }
`;
// Default properties needed for the MeterReading.
export const METER_READING_FRAGMENT = gql`
   fragment meterReadingInfo on MeterReading {
      id
      uuid
      date
      beginningMeter
      endMeterReading
      replacedDate
      estimatedUse
      imageData {
         uuid: imageHash
         id: imageKey
         imageS3
      }
      isDeleted
      meterId
      wellId
      note
   }
`;

// The properties of Client needed for the client queries. Always use the same properties to aid caching.
// TODO add isManaged when supported
export const CLIENT_FRAGMENT = gql`
   fragment clientInfo on Client {
      id
      uuid
      eulaAcknowleged
      name
      isManaged
      adminId
      maximumUsers
      addressLineOne
      addressLineTwo
      cityId
      city {
         id
         name
      }
      stateId
      state {
         id
         name
      }
      zipCode
      contactName
      phone
      email
      isDeleted
      fileUploads {
         id
         uuid
         clientId
         fileData {
            fileS3
            fileFilename
            uuid: fileHash
         }
         isDeleted
      }
   }
`;

// The properties of User needed for the user queries. Always use the same properties to aid caching.
export const USER_CLIENT_FRAGMENT = gql`
   fragment userClientInfo on User {
      id
      uuid
      isAdmin
      clientId
      username
      email
      facilityAccess
      fieldAccess
      wasteStorageAccess
      wellAccess
      isDeleted
   }
`;

// The properties of User needed for the user queries. Always use the same properties to aid caching.
export const USER_FRAGMENT = gql`
   fragment userInfo on User {
      id
      uuid
      isAdmin
      clientId
      managerName
      addressLineOne
      addressLineTwo
      city {
         id
         name
      }
      cityId
      state {
         id
         name
      }
      stateId
      zipCode
      phonePrimary
      phoneSecondary
      email
      username
      isDeleted
   }
`;

export const MONTHLY_BREAKDOWN_FRAGMENT = gql`
   fragment monthlyBreakdownInfo on MonthlyBreakdown {
      jan {
         id
         gallons
         acreFeet
         endMeterReading
      }
      feb {
         id
         gallons
         acreFeet
         endMeterReading
      }
      mar {
         id
         gallons
         acreFeet
         endMeterReading
      }
      apr {
         id
         acreFeet
         gallons
         endMeterReading
      }
      may {
         id
         gallons
         acreFeet
         endMeterReading
      }
      jun {
         id
         gallons
         acreFeet
         endMeterReading
      }
      jul {
         id
         gallons
         acreFeet
         endMeterReading
      }
      aug {
         id
         gallons
         acreFeet
         endMeterReading
      }
      sep {
         id
         gallons
         acreFeet
         endMeterReading
      }
      oct {
         id
         gallons
         acreFeet
         endMeterReading
      }
      nov {
         id
         gallons
         acreFeet
         endMeterReading
      }
      dec {
         id
         gallons
         acreFeet
         endMeterReading
      }
   }
`;
